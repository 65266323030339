/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import { MuiThemeProvider } from '@material-ui/core/styles';
import { configRequest } from 'modules/config/actions';
import React, { lazy, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import theme from 'theme/index';
import PrivateRoute from './PrivateRoute';
import Apps from 'containers/Apps';

const Login = lazy(() => import('containers/Login'));
const ConfigurationMFA = lazy(() => import('containers/ConfiguredMFA'));
const UserLoginSSO = lazy(() => import('containers/UserLoginSSO'));
const UserLoginSSOToken = lazy(() => import('containers/UserLoginSSOToken'));
const UserLoginCiscoDuo = lazy(() => import('containers/UserLoginCiscoDuo'));
const RequiredMFA = lazy(() => import('containers/RequiredMFA'));
const ForgotPassword = lazy(() => import('containers/ForgotPassword'));
const ChangePassword = lazy(() => import('containers/ChangePassword'));
const RegisterPassword = lazy(() => import('containers/RegisterPassword'));
const Home = lazy(() => import('containers/Home'));
const AutomaticHomeLoading = lazy(() =>
  import('containers/Home/AutomaticHomeLoading')
);
const Messages = lazy(() => import('containers/Messages'));
const TimeInstance = lazy(() => import('containers/TimeInstance'));
const PendingConnection = lazy(() => import('containers/PendingConnection'));
const Reconnect = lazy(() => import('containers/Reconnect'));
const DownloadPlugin = lazy(() => import('containers/DownloadPlugin'));
const VirtualizerChoice = lazy(() => import('containers/VirtualizerChoice'));
const UpdatePlugin = lazy(() => import('containers/UpdatePlugin'));
const FirstAccess = lazy(() => import('containers/FirstAccess'));
const Logout = lazy(() => import('containers/Logout'));
const NotFound = lazy(() => import('containers/NotFound'));
const AccessRestricted = lazy(() => import('containers/AccessRestricted'));
const ExpiredPassword = lazy(() => import('containers/ExpiredPassword'));

const Router = ({ configRequest, config }) => {
  const getFaviconEl = (): any => document.querySelector('link[rel="icon"]')!;

  React.useEffect(() => {
    configRequest();
  }, []);

  React.useEffect(() => {
    const favicon = getFaviconEl();
    if (config?.title) {
      window.document.title = config?.title;
    }
    if (config?.favicon && favicon?.href) {
      favicon.href = config?.favicon;
    }
  }, [config]);

  if (config?.reload) return <div>Loading...</div>;

  return (
    <div style={{ height: '100vh' }}>
      <Suspense fallback={<div>Loading...</div>}>
        <MuiThemeProvider theme={theme(config)}>
          <Switch>
            <Route path="/" component={Login} exact />
            <Route path="/sso/:domain?" component={UserLoginSSO} exact />
            <Route
              path="/login/duo-callback/"
              component={UserLoginCiscoDuo}
              exact
            />
            <Route
              path="/login/sso/:token?"
              component={UserLoginSSOToken}
              exact
            />
            <Route component={ExpiredPassword} path="/password/expired" />
            <Route path="/forgotPassword" component={ForgotPassword} exact />
            <Route path="/changePassword" component={ChangePassword} exact />
            <Route
              path="/register/:userUuid/:token"
              component={RegisterPassword}
              exact
            />
            <Route component={Logout} path="/logout" exact />
            <PrivateRoute
              path="/requiredMFA"
              component={RequiredMFA}
              noHeader
              exact
            />
            <PrivateRoute
              path="/configurationMFA"
              component={ConfigurationMFA}
              noHeader
              exact
            />
            <PrivateRoute component={Home} path="/home/environments" />
            <PrivateRoute
              component={AutomaticHomeLoading}
              path="/homeLoading"
            />
            <PrivateRoute
              component={AccessRestricted}
              path="/accessRestricted"
            />
            <PrivateRoute
              component={ExpiredPassword}
              path="/password/expired"
            />
            <PrivateRoute component={Messages} path="/messages" />
            <PrivateRoute component={Reconnect} path="/reconnect" />
            <PrivateRoute component={TimeInstance} path="/timeInstance" />
            <PrivateRoute component={UpdatePlugin} path="/updatePlugin" />
            <PrivateRoute
              component={PendingConnection}
              path="/pendingConnection"
            />
            <PrivateRoute
              component={VirtualizerChoice}
              path="/virtualizerChoice"
            />
            <PrivateRoute
              component={VirtualizerChoice}
              path="/launching/:executePlugin"
              exact
            />
            <PrivateRoute component={Apps} path="/apps/:id" exact />
            <PrivateRoute component={DownloadPlugin} path="/downloadPlugin" />
            <PrivateRoute component={FirstAccess} path="/firstAccess" />

            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </MuiThemeProvider>
      </Suspense>
    </div>
  );
};

const mapStateToProps = ({ config }) => ({ config });

export default withTranslation()(
  connect(mapStateToProps, { configRequest })(Router)
);
